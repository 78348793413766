export const RevTagsPsychArray = [
  { label: 'Any Tag', value: 'all', description: '' },
  {
    label: 'ATK',
    value: 'ATK',
    description: '-'
  },
  {
    label: 'Critical',
    value: 'Critical',
    description: '-'
  },
  {
    label: 'Heal',
    value: 'Heal',
    description: '-'
  },
  {
    label: 'Survival',
    value: 'Survival',
    description: '-'
  }
];
